* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Gotham';
}

a {
  font-family: 'Poppins' !important;
  line-height:initial !important;
  cursor: pointer !important;
}

.ht-lg
{
  height: 500px;
}

@media(min-width: 768px) {

p,h1,h2,h3,h4,h5,h6,a{
  font-family: 'Gotham';
}

}

p {
  font-size: 1rem !important;
  line-height: 25px !important;
}
#search-wrap button#basic-addon2 {
  position: absolute;
  top: 10px;
  right: 30px;
  padding: 4px;
  font-size: 30px;
  border: 0;
  background: transparent;
} 
figcaption {
  bottom: -300px;
}
.gallery  .image img {
  width: 100%;
  cursor: pointer;
}
figure.snip1527:hover figcaption {
  bottom: 0 !important;
}
.socialSare {
  z-index: 99;
}

@media(max-width: 767px) {

  .enqForMob {
    right: 4px !important;
}
  .MuiSpeedDial-root.MuiSpeedDial-directionUp {
    right: auto !important;
    left: 4px!important;
    bottom: 0!important;
}

  button.scrollTOp {
    display: none !important;
}

h1, h2, h3, h4, h5, h6, a {
  font-family: 'Poppins' !important;
}
.course-feature p {
  text-align: justify;
}

.hero__quote-animation {
  animation-name: none !important;
}

}