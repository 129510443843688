.App {
  text-align: center;
}
body{
  background: #f8f7f6;
}
h1,h2,h3,h4,h5,h6,a
{
  font-family: 'Gotham';
}
.videosSec {
  display: none;
}
a{text-decoration:none !important;}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.single-details-sidbar {
  background: #fff;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.image-container img {
  width: 100% !important;
}


.dflex-card img {
  width: 100%;
  box-shadow:0px 0px 28px rgb(0 0 0 / 5%);
  padding: 8px;
  border-radius: 5px;
}

section.course-details-area h3, section.course-details-area h4 {
  font-weight: 700;
  line-height: 34px;
  text-transform: uppercase;
  margin-bottom:0px;
  color: #f02f2f !important;
  font-style: normal;
  font-size: 24px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.react-multi-carousel-list {
  background: #fff;
  padding: 18px 0px;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 10%);
}
.sec-title h2 {
  font-weight: 900;
  font-size: 58px;
  text-transform: uppercase;
  color: #f02f2f;
  line-height: 1;
}
.sec-title h4 {
  color: #3f3c3c;
  font-weight: 500;
} 
div#root {
  background: #f8f7f6;
}
.logoItem {
  border-left: 1px solid #cecece;
  padding: 0 15px;
}
.logoItem img {
  width: 100%;
}


    
.ReactModal__Content.ReactModal__Content--after-open {
  max-width: 850px;
  transition: all 0.5s ease; 
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
background: rgba(0, 0, 0, 0.75) !important;
}
.myModal {
display: flex;
}
.imgModal {
  width: 40%;
}
.modalText {
  width: 60%;
  padding-left: 20px;
}
button.closeBtn {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  position: absolute;
  right: 20px;
  top: 10px;
  border: 0;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 999;
}
.imgModal img {
  width: 100%;
}
.programme-area .slick-next:before {
  left: -30px;
}
.sec-title h4 {
  text-transform: uppercase;
}

.testimonialsSection  .slick-prev:before
{
background-size: 70%;
    transform: rotate(180deg);
    opacity: 0.2 !important;
    top: -5px !important;
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
}

.testimonialsSection  .slick-next:before{
  top: -5px !important;
  background-color: #fff;
  left: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
}
.titleAdjust p div a {
  background: #cecece;
  padding: 10px;
  display: inline-block;
  color: #333;
  border-radius: 6px;
}
.titleAdjust p div {
  display: inline-block;
}
.titleAdjust ol li {
  margin: 13px 0;
  position: relative;
}
.titleAdjust ol li:after {
  content: "";
  position: absolute;
  height: 7px;
  width: 7px;
  background: #9c9696;
  left: -16px;
  top: 11px;
}
ul.dropdown-menu.show {
  z-index: 99999;
}
a.detailView h3 {
  font-family: 'Poppins' !important;
}




@media(max-width: 767px) {

  a.ViewMorebtn {
    width: 100%;
    color: white;
    background-color: #d30202;
    border-color: #91292d;
    padding: 7px 15px;
    display: inline-block;
    margin-top: 18px;
    max-width: 130px;
    text-align: center;
    border-radius: 4px;
}


  button.slick-arrow.slick-prev {
    display: none !important;
}
.order-xs-2 {
  order: 2!important;
}
.order-xs-1 {
  order: 1!important;
}
.viewDiv {
  max-height: 310px;
  position: relative;
  overflow: hidden;
} 

.resrchTxt.Slide {
  min-height: auto !important;
  max-width:100% !important;
  width:100% !important;
  justify-content: center;
}
.testimonials-area button.slick-arrow.slick-next {
  top: 31%;
  right: -10px !important;
}

.testimonials-area .slick-next:before {
  left: -30px;
  background-color: rgb(196, 196, 196);
  background-position: center;
  background-size: 70%;
  opacity: 0.6 !important;
  background-repeat: no-repeat;
}

  .imgModal {
    width: 100%;
}
.modalText {
  width: 100%;
  padding-left: 0;
  padding-top: 15px;
}
.myModal {
display: block !important;
}
button.closeBtn {
background: #fff !important;
opacity: 1;
padding: 5px;
}

 .testimonials-area .slick-next {
    right: 0 !important;
    z-index: 999;
}
.searchForm form.form .form-control select {
  height: 40px !important;
}

.searchForm form.form .react-tabs__tab {
  font-size: 13px !important;
  padding: 10px 5px !important;
}
.testimonials-area .slick-prev {
  left: 0 !important;;
  z-index: 99;
}

.testimonialsSection {
  padding: 50px 0 !important;
  background-size: cover !important;
}
.testimonials-area .slick-prev:before, .slick-next:before {
  opacity: 1;
  color:#000 !important;
}

  button.mb-menu-button p {
    color: #333 !important;
}
.searchForm form.form .form-control select {
  max-width: 100% !important;
  margin-right: 13px;
}
.sec-title h2, .subHeading, .formName h4, .StoriesData a h4 {
  font-family: 'Poppins' !important;
  font-weight: 700 !important;
}
.event-faq-area {
  padding: 30px 20px !important;
}

  button.mb-menu-button a {
    color: #333;
} 

button.mb-menu-button p {
  margin: 0 !important;
}

  .event-page .slick-next {
    right: 0;
}
.event-page .slick-prev {
  left: 0;
  z-index: 9;
}
.sec-title h2, .section-title h3 {
  font-size: 32px !important;
  background: transparent;
}
tbody, td, tfoot, th, thead, tr {
  vertical-align: baseline;
}
footer.footer1 .footerAbout {
  padding: 20px 0 !important;
}
}


/* comunity pages  */
.section-title.titleAdjust p span {
  font-family: 'Poppins' !important;
}

.colapseSidebar  h5:before {
  position: absolute;
  content: "";
  background: #ff2023;
  width: 50px;
  height: 2px;
  bottom: 0;
  left: 0;
}
.Apps {
  position: sticky;
  height: 100vh;
}
/* .Apps:after {
  background: linear-gradient(1800deg, rgba(0,0,0,0.6), rgba(0,0,0,0.2), rgba(0,0,0,0.6));
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 99;
  left: 0;
  top: 0;
} */

.slick-prev:before {
  content: '' !important;
  background: url(../public/assets/images/next.png);
  height: 30px;
  width: 30px;
  position: absolute;
  left: -20px;
  background-size: 100%;
  transform: rotate(180deg);
  opacity: 0.2 !important;
  top: -5px !important;
}
.impactSU .slick-next:before, .event-page-area .slick-next:before{
  left: 0;
}
.slick-next:before {
  content: '' !important;
  background: url(../public/assets/images/next.png);
  height: 30px;
  width: 30px;
  position: absolute;
  left: -20px;
  background-size: 100%;
  transform: rotate(0deg);
  opacity: 0.2 !important;
  top: -5px !important;
}
@media screen and (min-width:981px)
{
  a.ViewMorebtn
  {
    display:none;
  }
.mainHome .resrchTxt {
  position: absolute;
  bottom: 70px;
}
.mainHome .slider-btn {
  position: absolute;
  bottom: 55px;
}
}


.hidecurrent
{
  display:none;
}
#pageid-451 .healthWell,
#pageid-452 .healthWell,
#pageid-453 .healthWell,
#pageid-454 .healthWell,
#pageid-456 .healthWell,
#pageid-586  .healthWell,
#pageid-587  .healthWell,
#pageid-588 .healthWell,
#pageid-589 .healthWell,
#pageid-590 .healthWell,
#pageid-591 .healthWell,
#pageid-447 .healthWell,
#pageid-592 .healthWell,
#pageid-449 .energy-efficiency,
#pageid-592 .energy-efficiency,
#pageid-593 .energy-efficiency,
#pageid-594 .energy-efficiency,
#pageid-595 .energy-efficiency,
#pageid-596 .energy-efficiency,
#pageid-597 .energy-efficiency,
#pageid-598 .energy-efficiency,
#pageid-599 .energy-efficiency,
#pageid-600 .energy-efficiency,
#pageid-602 .energy-efficiency,
#pageid-675 .energy-efficiency,
#pageid-676 .energy-efficiency,
#pageid-604 .energy-efficiency,
#pageid-605 .energy-efficiency,
#pageid-607 .energy-efficiency,
#pageid-609 .energy-efficiency,
#pageid-611 .energy-efficiency,
#pageid-612 .energy-efficiency,
#pageid-614 .energy-efficiency,
#pageid-616 .energy-efficiency,
#pageid-450 .csr-initiatives,
#pageid-633 .csr-initiatives,
#pageid-634 .csr-initiatives,
#pageid-635 .csr-initiatives,
#pageid-636 .csr-initiatives,
#pageid-637 .csr-initiatives,
#pageid-638 .csr-initiatives,
#pageid-639 .csr-initiatives,
#pageid-640 .csr-initiatives,
#pageid-641 .csr-initiatives,
#pageid-642 .csr-initiatives,
#pageid-643 .csr-initiatives,
#pageid-645 .csr-initiatives,
#pageid-646 .csr-initiatives,
#pageid-647 .csr-initiatives,
#pageid-648 .csr-initiatives,
#pageid-649 .csr-initiatives,
#pageid-455 .inclusivity-gender-equality,
#pageid-650 .inclusivity-gender-equality,
#pageid-652 .inclusivity-gender-equality,
#pageid-653 .inclusivity-gender-equality,
#pageid-654 .inclusivity-gender-equality,
#pageid-655 .inclusivity-gender-equality,
#pageid-656 .inclusivity-gender-equality,
#pageid-657 .inclusivity-gender-equality,
#pageid-658 .inclusivity-gender-equality,
#pageid-659 .inclusivity-gender-equality,
#pageid-660 .inclusivity-gender-equality,
#pageid-661 .inclusivity-gender-equality,
#pageid-662 .inclusivity-gender-equality,
#pageid-663 .inclusivity-gender-equality,
#pageid-666 .inclusivity-gender-equality,
#pageid-667 .inclusivity-gender-equality,
#pageid-668 .inclusivity-gender-equality,
#pageid-669 .inclusivity-gender-equality,
#pageid-670 .inclusivity-gender-equality,
#pageid-671 .inclusivity-gender-equality,
#pageid-672 .inclusivity-gender-equality,
#pageid-673 .inclusivity-gender-equality,

#pageid-622 .safe-water
{
  display:block;
}


@media only screen and (max-width: 980px) and (min-width: 768px)  {

  .Apps {
    height: 100%;
}
.introVDO iframe {
  width: 100%;
  height: 360px!important;
  top: 0;
  margin: 30px 0 !important;
}
.galImage {
  max-height: 200px !important;
}

}
